import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';

export const getDashboardStudentsList = createAsyncThunk(
  'staffDashboard/dashboard-students',
  async ({loaderDispatch, schoolId, currentPage, searchTerm}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const params = {
        per_page: 10,
        page: currentPage,
        school_id: schoolId,
        ...(searchTerm && {search: searchTerm}),
      };
      const response = await axiosPrivateInstance.get('/dashboard-students', {
        params,
      });
      loaderDispatch(setLoading(false));
      return response?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      throw error.response.data.message;
    }
  },
);

export const getStudentDashboardDetails = createAsyncThunk(
  'studentDetails/dashboard',
  async ({loaderDispatch, studentId, schoolId}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const params = {
        ...(schoolId && {school_id: schoolId}),
      };
      const response = await axiosPrivateInstance.get(`students/${studentId}`, {
        params,
      });
      loaderDispatch(setLoading(false));
      return response?.data?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      throw error.response.data.message;
    }
  },
);

export const getDashboardStats = createAsyncThunk(
  'dashboard/dashboard-stats',
  async ({loaderDispatch, schoolId}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.get('/dashboard-statistics', {
        params: {school_id: schoolId},
      });
      loaderDispatch(setLoading(false));
      return response?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      throw error.response.data.message;
    }
  },
);

export const getSuperAdminDashboardDistricts = createAsyncThunk(
  'superAdmin/dashboard-districts',
  async ({loaderDispatch, currentPage, countryId, stateId}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const params = {
        per_page: 10,
        page: currentPage,
        ...(countryId && {country_id: countryId}),
        ...(stateId && {state_id: stateId}),
      };
      const response = await axiosPrivateInstance.get('/dashboard-district', {
        params,
      });
      loaderDispatch(setLoading(false));
      return response?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      throw error.response.data.message;
    }
  },
);
