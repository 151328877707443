import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {setLocalStorageItem} from '../../services/local-storage';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';
import {showToast} from '../../components/Toast/Toast';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {removeLocalStorageItem} from '../../services/local-storage';
import {resetState} from '../../store/reducers/authReducers';

export const login = createAsyncThunk('auth/login', async credentials => {
  try {
    const response = await axiosPrivateInstance.post('/login', credentials);
    const {access_token, refresh_token, expires_in} = response.data.data;
    const userRoleDetails = response?.data?.data?.user?.role;
    const selectedMediaId =
      response?.data?.data?.user?.student_data?.avatar?.media || null;
    const expiryTime = Math.floor(Date.now() / 1000) + expires_in;
    const userTeacherImage =
      response?.data?.data?.user?.teacher_data?.profile_image?.path;
    const userCounsellorImage =
      response?.data?.data?.user?.counsellor_data?.profile_image?.path;
    const earnedTokens =
      response?.data?.data?.user?.student_data?.earned_tokens;
    const myProfile = {
      ...response.data?.data?.user,
      myImage:
        userRoleDetails.name == 'teacher'
          ? userTeacherImage
          : userRoleDetails.name == 'counsellor'
          ? userCounsellorImage
          : null,
    };

    setLocalStorageItem('accessToken', access_token);
    setLocalStorageItem('refreshToken', refresh_token);
    setLocalStorageItem('expiryTime', expiryTime);
    setLocalStorageItem('userRole', userRoleDetails);
    setLocalStorageItem('myProfile', myProfile);
    if (userRoleDetails.name == 'student') {
      if (selectedMediaId) {
        setLocalStorageItem('studentIslandAvatar', selectedMediaId);
      }
      if (earnedTokens) {
        setLocalStorageItem('earned_tokens', earnedTokens);
      }
    }
    return response;
  } catch (error) {
    throw error;
  }
});

// export const loginWithGoogle = createAsyncThunk(
//   'auth/loginWithGoogle',
//   async (payload, thunkAPI) => {
//     try {
//       const response = await axiosPrivateInstance.post('/2fa/enable', payload);
//       const qrCodeImage = await axiosPrivateInstance.post('/2fa/qr-image', {
//         totpUri: response.data.qrcode_url,
//       });

//       return qrCodeImage.data;
//     } catch (error) {
//       throw error;
//     }
//   },
// );

export const loginWithGoogle = createAsyncThunk(
  'auth/loginWithGoogle',
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.post('/2fa/enable', payload);
      // const qrCodeImage = await axiosPrivateInstance.post('/2fa/qr-image', {
      //   totpUri: response.data.qrcode_url,
      // });

      return response.data.qrcode_url;
    } catch (error) {
      throw error;
    }
  },
);

export const generateQRCode = createAsyncThunk(
  'auth/generateQRCode',
  async (data, thunkAPI) => {
    try {
      const qrCodeImage = await axiosPrivateInstance.post('/2fa/qr-image', {
        totpUri: data?.payload,
      });

      return qrCodeImage.data;
    } catch (error) {
      throw error;
    }
  },
);

export const loginWith2FA = createAsyncThunk(
  'auth/loginWith2FA',
  async (payload, thunkAPI) => {
    try {
      const data = {
        email: payload?.email,
        totp: Number(payload?.passcode),
      };
      const response = await axiosPrivateInstance.post('/2fa/verify', data);
      // Extract only serializable data
      const {data: responseData} = response;

      const {access_token, refresh_token, expires_in} = response.data.data;
      const userRoleDetails = response?.data?.data?.user?.role;
      const selectedMediaId =
        response?.data?.data?.user?.student_data?.avatar?.media || null;
      const expiryTime = Math.floor(Date.now() / 1000) + expires_in;
      const userTeacherImage =
        response?.data?.data?.user?.teacher_data?.profile_image?.path;
      const userCounsellorImage =
        response?.data?.data?.user?.counsellor_data?.profile_image?.path;
      const earnedTokens =
        response?.data?.data?.user?.student_data?.earned_tokens;
      const myProfile = {
        ...response.data?.data?.user,
        myImage:
          userRoleDetails.name == 'teacher'
            ? userTeacherImage
            : userRoleDetails.name == 'counsellor'
            ? userCounsellorImage
            : null,
      };

      setLocalStorageItem('accessToken', access_token);
      setLocalStorageItem('refreshToken', refresh_token);
      setLocalStorageItem('expiryTime', expiryTime);
      setLocalStorageItem('userRole', userRoleDetails);
      setLocalStorageItem('myProfile', myProfile);
      if (userRoleDetails.name == 'student') {
        if (selectedMediaId) {
          setLocalStorageItem('studentIslandAvatar', selectedMediaId);
        }
        if (earnedTokens) {
          setLocalStorageItem('earned_tokens', earnedTokens);
        }
      }
      return responseData; // Ensure this only contains serializable values
    } catch (error) {
      console.error(error.response?.data || error, 'error');
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (email, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.post('/forgot-password', {
        email,
      });
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({token, email, password}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.post('/reset-password', {
        token,
        email,
        password,
      });
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);

export const verifyEmail = createAsyncThunk(
  'auth/verifyEmail',
  async ({id, hash, intl, setError, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const parsedId = parseInt(id);
      const response = await axiosPrivateInstance.get(
        `/email/verify/${parsedId}/${hash}`,
      );
      const access_token = response?.data?.data?.access_token;
      const userRole = response?.data?.data?.role;
      setLocalStorageItem('accessToken', access_token);
      setLocalStorageItem('userRole', userRole);
      loaderDispatch(setLoading(false));
      showToast(
        getLocalizedMessage(intl, 'label.email_verified_success'),
        'success',
      );
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      setError({isError: true, errorMessage: error?.response?.data?.message});
      showToast(getLocalizedMessage(intl, 'link.expired'), 'error');
      throw error;
    }
  },
);

export const setPassword = createAsyncThunk(
  'auth/setPassword',
  async (
    {password, token, intl, setError, loaderDispatch, dispatch},
    thunkAPI,
  ) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.post('/set-password', {
        password,
        token,
      });
      loaderDispatch(setLoading(false));
      removeLocalStorageItem('accessToken');
      // dispatch(logout());
      dispatch(resetState());
      showToast(getLocalizedMessage(intl, 'password_set_success'), 'success');
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      setError({isError: true, errorMessage: error?.response?.data?.message});
      showToast(getLocalizedMessage(intl, 'link.expired'), 'error');
      throw error.response.data.message;
    }
  },
);

export const logout = createAsyncThunk(
  'logout',
  async function (_payload, thunkAPI) {
    thunkAPI.dispatch({type: 'logout'});
  },
);

export const cleverLoginPage = createAsyncThunk(
  'auth/cleverLoginPage',
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.get(
        '/oauth/clever/redirect',
        payload,
      );
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);

export const getCleverToken = createAsyncThunk(
  'auth/getCleverToken',
  async ({data}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.get(
        `/oauth/clever/callback?code=${data.code}`,
      );

      const {access_token, refresh_token, expires_in} = response.data.data;

      const userRoleDetails = response?.data?.data?.user?.role;
      const selectedMediaId =
        response?.data?.data?.user?.student_data?.avatar?.media || null;
      const expiryTime = Math.floor(Date.now() / 1000) + expires_in;
      const userTeacherImage =
        response?.data?.data?.user?.teacher_data?.profile_image?.path;
      const userCounsellorImage =
        response?.data?.data?.user?.counsellor_data?.profile_image?.path;
      const earnedTokens =
        response?.data?.data?.user?.student_data?.earned_tokens;
      const myProfile = {
        ...response.data?.data?.user,
        myImage:
          userRoleDetails.name == 'teacher'
            ? userTeacherImage
            : userRoleDetails.name == 'counsellor'
            ? userCounsellorImage
            : null,
      };

      setLocalStorageItem('accessToken', access_token);
      setLocalStorageItem('refreshToken', refresh_token);
      setLocalStorageItem('expiryTime', expiryTime);
      setLocalStorageItem('userRole', userRoleDetails);
      setLocalStorageItem('myProfile', myProfile);
      if (userRoleDetails.name == 'student') {
        if (selectedMediaId) {
          setLocalStorageItem('studentIslandAvatar', selectedMediaId);
        }
        if (earnedTokens) {
          setLocalStorageItem('earned_tokens', earnedTokens);
        }
      }

      console.log(response.data, 'clever token response');
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);
