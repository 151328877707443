import * as Yup from 'yup';
import {
  EMAIL_REGEX,
  PASSWORD_REGEX_WITH_SPECIAL_CHARACTERS,
  NAME_REGEX,
} from '../core/validation';
export const addStudentFormFieldNames = {
  MEDIA_ID: 'media_id',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  CREATE_NEW_PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirm_password',
  COUNSELLOR_ID: 'counsellor_id',
  TEACHER_ID: 'teacher_id',
  GRADE: 'grade_id',
  SECTION: 'section_id',
  SCHOOL_ID: 'school_id',
};
export const addStudentFormInitialValues = {
  [addStudentFormFieldNames.MEDIA_ID]: '',
  [addStudentFormFieldNames.FIRST_NAME]: '',
  [addStudentFormFieldNames.LAST_NAME]: '',
  [addStudentFormFieldNames.EMAIL]: '',
  [addStudentFormFieldNames.CREATE_NEW_PASSWORD]: '',
  [addStudentFormFieldNames.CONFIRM_PASSWORD]: '',
  [addStudentFormFieldNames.COUNSELLOR_ID]: '',
  [addStudentFormFieldNames.TEACHER_ID]: '',
  [addStudentFormFieldNames.GRADE]: '',
  [addStudentFormFieldNames.SECTION]: '',
  [addStudentFormFieldNames.SCHOOL_ID]: '',
};
export const validationSchema = (intl, id) => {
  let schema = Yup.object().shape({
    [addStudentFormFieldNames.FIRST_NAME]: Yup.string()
      .required(intl.formatMessage({id: 'label.first_name_required'}))
      .max(
        150,
        intl.formatMessage({id: 'label.fieldError.first_name.maxLength'}),
      )
      .matches(
        NAME_REGEX,
        intl.formatMessage({id: 'label.fieldError.invalid.firstName'}),
      ),
    [addStudentFormFieldNames.LAST_NAME]: Yup.string()
      .required(intl.formatMessage({id: 'label.last_name_required'}))
      .max(
        150,
        intl.formatMessage({id: 'label.fieldError.first_name.maxLength'}),
      )
      .matches(
        NAME_REGEX,
        intl.formatMessage({id: 'label.fieldError.invalid.lastName'}),
      ),
    [addStudentFormFieldNames.EMAIL]: Yup.string()
      .required(intl.formatMessage({id: 'label.email_required'}))
      .matches(EMAIL_REGEX, intl.formatMessage({id: 'label.fieldError.email'})),

    [addStudentFormFieldNames.COUNSELLOR_ID]: Yup.string().required(
      intl.formatMessage({id: 'label.counsellor_required'}),
    ),
    [addStudentFormFieldNames.TEACHER_ID]: Yup.string().required(
      intl.formatMessage({id: 'label.teacher_required'}),
    ),
    [addStudentFormFieldNames.GRADE]: Yup.string().required(
      intl.formatMessage({id: 'label.grade_required'}),
    ),
    [addStudentFormFieldNames.SECTION]: Yup.string().required(
      intl.formatMessage({id: 'label.group_required'}),
    ),
  });

  if (!id) {
    schema = schema.shape({
      [addStudentFormFieldNames.CREATE_NEW_PASSWORD]: Yup.string()
        .required(intl.formatMessage({id: 'label.password_required'}))
        .matches(
          PASSWORD_REGEX_WITH_SPECIAL_CHARACTERS,
          intl.formatMessage({id: 'label.fieldError.password'}),
        ),
      [addStudentFormFieldNames.CONFIRM_PASSWORD]: Yup.string()
        .required(intl.formatMessage({id: 'label.confirm_password_required'}))
        .oneOf(
          [Yup.ref(addStudentFormFieldNames.CREATE_NEW_PASSWORD)],
          intl.formatMessage({id: 'label.password_not_matched'}),
        ),
    });
  }

  return schema;
};
